<template>
    <Layout id="layouttttttttttt">
  
      <div class="card mt-2">
        <div class="card-body">
          <h1>Account Agios</h1>        
          <div class="mt-3"> 
            <b-form-group :class="AccountActivity.Currency ? `col-md-11` : `col-md-12`" label="RIB"
                                        label-for="input-rib">
                                        <multiselect v-model="RIB"  id="input-rib"
                                            :options="selectedRibs" @input="getAccountActivity(RIB)" />
                                            
                                    </b-form-group>            
                  <b-table style="height: max-content !important;" :items="AccountActivity" selectable
                    select-mode="single" :fields="AccountActivityFields" class="mr-2  w-100" outlined
                    >
                  </b-table> 
          </div>
        </div>
  
      </div>
  
    </Layout>
  </template>
  
  <script>
  
  import Layout from "./layouts/main";
  import axios, { all } from "axios";
  //import swal from "sweetalert";
  import Multiselect from "vue-multiselect";
  
  /**
   * Invoice component
   */
  export default {
    methods: {
    },
    data() {
      return {
        
        AccountActivityy: {
                RIB: "",
                Balance: "",
                Currency: "",
                activity: []
            },
            selectedRibs: [],
        AccountActivity: [],
        color: "",
        AccountActivityFields: [
          "Code",
          "Currency",
          "Compte Bancaire",
          "Date Operation",
          "Libelle",
          "Reference",
          "Montant",          
        ],
        InvoicesList: [],
        bool: false,
  
        bankName: "",
        type: "",
        invoiceObject: [],
        transactionObject: [],
        BankNames: [],
        RIB: "",
        InoviceType: ["Credit", "Debit"], 
        typeT: "",
        reconciliationCount: 0,
        reconciliation: {
          count: 0,
        },
        count: 0,
  
      };
    },
    methods: {     
       getribs() {
       axios.get(`https://finex.4help.tn/api/v1/getribs/${localStorage.getItem("societe").slice(1,-1)}`).then(res => { res.data.map(obj => this.selectedRibs.push(obj.RIB) )})  
      },
      async getAccountActivity(RIB) {
        console.log(RIB)
        if (RIB != null)
        {
          try {
          this.AccountActivity = await axios.get(`https://finex.4help.tn/api/v1/getAgiosByRIB/${RIB}/${localStorage.getItem("societe").slice(1,-1)}`)
            .then(res => {  console.log(res.data.agios)
              return res.data.agios })
          //console.log(this.AccountActivity)
        } catch (e) {
          throw new Error('error in request : getAccountActivity')
        }
        }
      }
    },  
    created() {     
      this.getRibs();
      this.type = null;
        
    },
    components: {
      Layout,
      Multiselect,
    },
  
    watch: {
      
    },
  };
  </script>
  <style scoped>
  .btn-lg {
  
    line-height: 0.7 !important;
  
  }
  
  .table-centered tr,
  .table-centered td {
    vertical-align: top !important;
  }</style>
  